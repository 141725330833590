<template>
  <div class="single-report-selector">
    <rectangle-radio-bar class="single-report-selector-rectangle-radio-bar" :dataArray="reportTypeArray"
      :selectedItem="selectedReportType" @onSelected="onReportTypeSelected"></rectangle-radio-bar>
    <dot-date-picker class="single-report-selector-date-picker" :uid="uid" :hospitalId="hospitalId"
      :inputSingleReportCreateDate="singleReportCreateDate" :clearable="false"
      @onDaySelected="onDaySelected"></dot-date-picker>
    <list-picker v-if="selectedReportType == '血氧报告'" class="single-report-selector-list-picker"
      :arrowIcon="require('@imgs/report-arrow-down.png')" :inputInfoArray="signgleBloodOxygenReportTitleList"
      :inputSelectedInfo="selectedSingleBloodOxygenReport?.time" @onSelectedIndexChanged="onBloodOxygenReportPicked">
    </list-picker>
  </div>
</template>



<script>
import RectangleRadioBar from "@c/common/rectangle-radio-bar.vue";
import ListPicker from "@c/common/list-picker/list-picker.vue";
import DotDatePicker from "@c/report/report-type-select-bar/single-report-selector/dot-date-picker.vue";
export default {
  components: {
    RectangleRadioBar,
    ListPicker,
    DotDatePicker,
  },

  props: {
    uid: Number,
    reportTypeArray: Array,
    inputSelectedReportType: String,
    inputSingleBreathReportId: Number,
    inputSingleOxygenReportId: Number,
    hospitalId: String,
  },

  data() {
    return {
      //报告类型
      selectedReportType: undefined,
      //呼吸报告
      singleBreathReportId: undefined,
      //报告日期
      singleReportCreateDate: undefined,
      inputSingleReportCreateDate: undefined,
      //血氧报告
      singleBloodOxygenReportId: undefined,
      singleBloodOxygenReportArray: [],
      selectedSingleBloodOxygenReport: undefined,
    };
  },

  computed: {
    signgleBloodOxygenReportTitleList() {
      return this.singleBloodOxygenReportArray.map((item) => {
        return item.time;
      });
    },
  },

  watch: {
    async inputSingleBreathReportId(value) {
      try {
        //报告类型
        this.selectedReportType = this.inputSelectedReportType;
        if (this.selectedReportType !== '呼吸机报告') {
          return
        }
        //单日呼吸报告
        this.singleBreathReportId = value;
        //单日报告创建时间（需要根据单日呼吸报告Id查询）
        const singleReportCreateTime = await this.getSingleReportDate();
        this.inputSingleReportCreateDate = new Date(singleReportCreateTime);
        this.singleReportCreateDate = this.inputSingleReportCreateDate;
        //默认单日血氧报告（需要根据单日报告创建时间查询）
        this.singleBloodOxygenReportArray = await this.getBloodOxygenReportList();
        this.selectedSingleBloodOxygenReport =
          this.singleBloodOxygenReportArray[this.singleBloodOxygenReportArray.length - 1]
        this.switchSingleReport();
      } catch (error) {
        this.$toast.showRed(error)
      }
    },

    async inputSingleOxygenReportId(value) {
      try {
        //报告类型
        this.selectedReportType = this.inputSelectedReportType;
        if (this.selectedReportType !== '血氧报告') {
          return
        }
        //单日呼吸报告
        this.singleBloodOxygenReportId = value;
        //单日报告创建时间（需要根据单日呼吸报告Id查询）
        const singleReportCreateTime = await this.getSingleBloodOxygenReportDate();
        this.inputSingleReportCreateDate = new Date(singleReportCreateTime);
        this.singleReportCreateDate = this.inputSingleReportCreateDate;
        //默认单日血氧报告（需要根据单日报告创建时间查询）
        this.singleBloodOxygenReportArray = await this.getBloodOxygenReportList();
        this.selectedSingleBloodOxygenReport =
          this.singleBloodOxygenReportArray.find(el => el.reportId == this.singleBloodOxygenReportId)
        this.switchSingleReport();
      } catch (error) {
        this.$toast.showRed(error)
      }
    }
  },

  methods: {
    async onReportTypeSelected(index) {
      try {
        this.selectedReportType = this.reportTypeArray[index];
        if (this.selectedReportType === '呼吸机报告' && !this.singleBreathReportId) {
          this.singleBreathReportId = await this.getSingleBreathReportId();
        }
        this.switchSingleReport();
      } catch (error) {

      }

    },

    async onDaySelected(value) {
      try {
        //单日报告创建时间
        this.singleReportCreateDate = value;
        //单日呼吸报告（需要根据单日报告创建时间查询）
        this.singleBreathReportId = await this.getSingleBreathReportId();
        //默认单日血氧报告（需要根据单日报告创建时间查询）
        this.singleBloodOxygenReportArray = await this.getBloodOxygenReportList();
        this.selectedSingleBloodOxygenReport =
          this.singleBloodOxygenReportArray[this.singleBloodOxygenReportArray.length - 1]
        this.switchSingleReport();
      } catch (error) {
        this.$toast.showRed(error)
      }

    },

    onBloodOxygenReportPicked(index) {
      this.selectedSingleBloodOxygenReport =
        this.singleBloodOxygenReportArray[index];
      this.switchSingleReport();
    },

    async getSingleReportDate() {
      try {
        const params = {
          "reportId": this.singleBreathReportId
        };
        const data = await this.$api.getReportInfo(params);
        const rawSingleReportCreateTime = data.startTime
        const rawSingleReportCreateDate = new Date(rawSingleReportCreateTime)
        const 小于正午12点 = rawSingleReportCreateDate < this.getAM12Time(rawSingleReportCreateDate)
        return 小于正午12点 ? rawSingleReportCreateTime - 24 * 3600 * 1000 : rawSingleReportCreateTime;
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    async getSingleBloodOxygenReportDate() {
      const params = {
        reportId: this.singleBloodOxygenReportId,
      };
      try {
        const data = await this.$api.getBloodOxygenReportDetail(params);
        const rawSingleReportCreateDate = new Date(data.startTime)
        const rawSingleReportCreateTime = rawSingleReportCreateDate.getTime()
        const 小于正午12点 = rawSingleReportCreateDate < this.getAM12Time(rawSingleReportCreateDate)
        return 小于正午12点 ? rawSingleReportCreateTime - 24 * 3600 * 1000 : rawSingleReportCreateTime;
      } catch {
        this.$toast.showRed(error);
      }

    },

    async getBloodOxygenReportList() {
      try {
        const tempDate = Date.parse(this.singleReportCreateDate) / 1000
        const params = {
          date: isNaN(tempDate) ? 0 : tempDate,
          patientId: this.uid,
        };
        return await this.$api.getBloodOxygenReportList(params) ?? [];
      } catch (error) {
        throw error
      }
    },

    async getSingleBreathReportId() {
      try {
        //当日中午12点到第二天中午12点
        const startTime = this.getAM12Time(this.singleReportCreateDate);
        const startTimeStamp = Date.parse(startTime) / 1000;
        const endTimeStamp = startTimeStamp + 24 * 60 * 60;
        const params = {
          hospitalId: this.hospitalId,
          uid: this.uid,
          start: startTimeStamp,
          end: endTimeStamp,
        };
        const data = await this.$api.getReportInfo(params);
        return data.id;
      } catch (error) {
        this.$toast.showRed(error);
      }

    },

    switchSingleReport() {
      this.$emit("onSingleReortSelected", {
        selectedReportType: this.selectedReportType,
        singleBreathReportId: this.singleBreathReportId,
        singleBloodOxygenReportId:
          this.selectedSingleBloodOxygenReport?.reportId,
      });
    },

    getAM12Time(date) {
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
      return new Date(year, month, day, 12, 0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.single-report-selector {
  display: flex;
  align-items: center;

  &-rectangle-radio-bar {
    width: 200px;
  }

  &-date-picker {
    width: 272px;
    height: 32px;
    margin-left: 20px;
  }

  /deep/.el-input__inner {
    width: 272px;
    height: 32px;
  }

  /deep/.el-input__icon {
    line-height: 32px; //图片居中
  }

  &-list-picker {
    width: 272px;
    height: 32px;
    margin-left: 20px;
  }
}
</style>