<template>
  <div class="report-frame">
    <report-type-select-bar ref="reportTypeSelectBar" :reportTypeInitialInfo="reportTypeInitialInfo"
      @onSwitchReport="onSwitchReport" @onPrint="onPrint" @onDownload="onDownload"
      @onShare="onShare"></report-type-select-bar>
    <div class="report-frame-content">
      <component :is="currentComponent" ref="component" :reportTypeInitialInfo="reportTypeInitialInfo"
        :selectedInfo="selectedInfo" @onNoReport="onNoReport"></component>
    </div>
  </div>
</template>

<script>
import ReportTypeSelectBar from "@c/report/report-type-select-bar/report-type-select-bar.vue";
import { DateTool } from "@js/date-tool.js";
import { MessageBox } from 'element-ui';
export default {
  components: {
    ReportTypeSelectBar,
    Empty: () => import("@c/report/report/empty.vue"),
    SingleReport: () => import("@c/report/report/single-report.vue"),
    SingleReportLite: () => import("@c/report/report/single-report-lite.vue"),
    Report: () => import("@c/report/report/report.vue"),
    ReportLite: () => import("@c/report/report/report-lite.vue"),
    SingleBloodOxygenReport: () =>
      import(
        "@c/report/report/single-blood-oxygen-report/single-blood-oxygen-report.vue"
      ),
  },

  data() {
    return {
      currentComponent: "",
      reportTypeInitialInfo: undefined,
      selectedInfo: undefined,
      pdfUrl: null,
      isLoadingPdf: false
    };
  },

  created() {
    const uid = parseInt(this.$route.query.uid)
    //报告列表进来时
    const singleReportID = parseInt(this.$route.query.singleReportID)
    //用户详情进来时
    const statisticsDay = this.$route.query.statisticsDay
    const statisticsDuration = this.$route.query.statisticsDuration?.map((item) => { return parseInt(item) })
    const hospitalId = this.$route.query.hospitalId
    //报告类型 1:呼吸机 2:血氧
    const reportType = this.$route.query.reportType ?? 1
    const singleBloodOxygenReportId = this.$route.query.singleBloodOxygenReportId ?? null
    this.reportTypeInitialInfo = {
      uid,
      singleReportID,
      inputSelectedTime: singleReportID ? null : { statisticsDay, statisticsDuration },
      hospitalId,
      reportType,
      singleBloodOxygenReportId,
    }

  },

  methods: {
    onSwitchReport(selectedInfo) {
      this.pdfUrl = null
      this.selectedInfo = selectedInfo;
      this.currentComponent = this.switchReport();
    },

    onNoReport() {
      this.currentComponent = "empty";
    },

    onPrint() {
      window.print();
    },

    async onDownload() {
      if (this.currentComponent === "empty") {
        this.$toast.showRed("当前无报告")
        return
      }
      if (this.currentComponent != 'single-blood-oxygen-report') {
        this.$toast.showRed("暂未开放")
        return
      }
      if (!this.pdfUrl) {
        try {
          await this.generatePdfUrl()
          window.open(this.pdfUrl ?? '')
        } catch (error) {
          this.$toast.showRed(error)
        }
      } else {
        window.open(this.pdfUrl ?? '')
      }
    },

    async onShare() {
      if (this.currentComponent === "empty") {
        this.$toast.showRed("当前无报告")
        return
      }
      try {
        if (!this.pdfUrl) { await this.generatePdfUrl() }
        const time = DateTool.dateToStr(this.$refs.reportTypeSelectBar.$refs.singleReportSelecor.singleReportCreateDate, "YYYY年MM月DD日")
        const name = this.$refs.component.reportDetailModel?.nameStr ?? ''
        const title = `【NPPV治疗服务血氧监测报告${time}${name}】`
        let downloadURL = this.pdfUrl ?? ''
        MessageBox.alert(title + downloadURL, '分享内容', {
          confirmButtonText: '确定',
          callback: () => {
            this.$copyText(title + downloadURL)
          }
        });
      } catch (error) {
        this.$toast.showRed(error)
      }
    },

    async generatePdfUrl() {
      if (this.isLoadingPdf) {
        return
      }
      this.isLoadingPdf = true
      const urlParams = new URLSearchParams(window.location.search);
      // 修改参数的值
      urlParams.set('reportType', '2');
      urlParams.set('singleBloodOxygenReportId', `${this.$refs.reportTypeSelectBar.$refs.singleReportSelecor.selectedSingleBloodOxygenReport.reportId}`);
      const updatedParamsString = urlParams.toString();
      const updatedUrl = `${window.location.origin}${window.location.pathname}?${updatedParamsString}`;
      try {
        const data = await this.$api.generatePdf({
          url: updatedUrl,
          inline: true
        })
        this.pdfUrl = data
      } catch (error) {
        throw error
      } finally {
        this.isLoadingPdf = false
      }
    },

    switchReport() {
      switch (this.selectedInfo.selectedDayType) {
        case "单天":
          switch (this.selectedInfo.selectedReportType) {
            case "呼吸机报告":
              switch (this.selectedInfo.selectedEdition) {
                case "医生版":
                  return "single-report";
                case "患者版":
                  return "single-report-lite";
                default:
                  break;
              }
            case "血氧报告":
              return "single-blood-oxygen-report";
            default:
              break;
          }
        case "多天":
          switch (this.selectedInfo.selectedEdition) {
            case "医生版":
              return "report";
            case "患者版":
              return "report-lite";
            default:
              break;
          }
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.report-frame {
  background-color: white;

  &-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
</style>

<style lang="scss">
.el-message-box__message p {
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-all;
}
</style>