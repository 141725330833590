<template>
  <div class="pick-list-list-picker">
    <div
      v-if="isShowList"
      class="pick-list-list-picker-mask"
      @click="onMaskClick"
    ></div>
    <pick-list
      class="pick-list-list-picker-list"
      :itemArray="itemArray"
      :isShowList="isShowList"
      :initialItem="initialItem"
      :itemHeight="32"
      :paddingHeight="0"
      @onSelected="onSelected"
    ></pick-list>
  </div>
</template>

<script>
import PickList from "@c/common/pick-list/pick-list.vue";
export default {
  components: {
    PickList,
  },

  props: {
    isShowList: {
      type: Boolean,
      default: false,
    },

    itemArray: {
      type: Array,
      default: [],
    },

    initialItem: String,
  },

  methods: {
    onSelected(index) {
      this.$emit("onSelected", index);
    },

    onMaskClick() {
      this.$emit("onMaskClick");
    },
  },
};
</script>

<style lang="scss" scoped>
.pick-list-list-picker {
  &-mask {
    @include z-index-2;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &-list {
    /deep/.pick-list {
      &-item {
        background-color: white;
        color: rgba($color: black, $alpha: 0.65);
        font-size: 14px;
        border-bottom: 1px solid rgba($color: black, $alpha: 0.09);
        display: flex;
        padding-left: 13px;

        &:hover {
          background-color: #f7f7f7;
        }

        &-selected {
          background-color: #e6f7ff;

          &:hover {
            background-color: #e6f7ff;
          }
        }
      }
    }
  }
}
</style>